import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  Subject,
} from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import {
  GuidedExperienceInstanceDTO,
  WindowMessageEventName,
} from '@next/shared/common';

@Component({
  selector: 'next-main',
  templateUrl: './main.component.html',
})
export class MainComponent implements OnDestroy {
  obsCleanup: Subject<void> = new Subject<void>();
  instance$: BehaviorSubject<GuidedExperienceInstanceDTO> = new BehaviorSubject<GuidedExperienceInstanceDTO>(null);
  viewMode: string = 'web';

  constructor(private route: ActivatedRoute) {
    this.viewMode = this.route.snapshot.routeConfig.path === 'pdf' ? 'pdf' : 'web';
    this.onPreviewWindowRefresh().subscribe();
    this.onResolvers().subscribe();
    this.emitViewerInit();
  }

  ngOnDestroy(): void {
    this.obsCleanup.next();
    this.obsCleanup.complete();
  }

  private emitViewerInit(): void {
    const initMessage = { eventName: WindowMessageEventName.ViewerInitialized, key: null, value: null, error: null };
    if (window.parent) window.parent.postMessage(initMessage, document.referrer || "*");
    if (window.opener) window.opener.postMessage(initMessage, window.opener.location.origin || "*");
  }

  private onPreviewWindowRefresh(): Observable<void> {
    return fromEvent(window, "message").pipe(
      map((messageEvent: MessageEvent) => messageEvent.data),
      filter(message => message === WindowMessageEventName.RefreshPreviewWindow),
      tap(() => location.reload()),
      takeUntil(this.obsCleanup));
  }

  private onResolvers(): Observable<GuidedExperienceInstanceDTO> {
    if (this.hasEmbeddedPrefill()) {
      const embeddedPrefill$ = fromEvent(window, "message").pipe(
        map((message: MessageEvent) => message.data),
        filter((message: any) => message.eventName === WindowMessageEventName.EmbeddedPrefill),
        first(),
        takeUntil(this.obsCleanup));

      return combineLatest([this.route.data, embeddedPrefill$]).pipe(
        map(([instance, prefillMessage]) => Object.assign(instance, { prefill: prefillMessage.data }) as GuidedExperienceInstanceDTO),
        tap(instance => this.instance$.next(instance)),
        takeUntil(this.obsCleanup));
    }
    else {
      return this.route.data.pipe(
        map((data) => data as GuidedExperienceInstanceDTO),
        tap(instance => this.instance$.next(instance)),
        takeUntil(this.obsCleanup));
    }
  }

  private hasEmbeddedPrefill(): boolean {
    const query: unknown = this.route.snapshot.queryParamMap.get('embeddedPrefill');
    switch (query) {
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return false;
    }
  };

}
