<div class="spinner-container" *ngIf='loading' [@fadeInOut]>
  <img src='assets/images/interlace.gif' alt='Loading In Progress' class='spinner mb-2'>
  <div class='container-fluid justify-content-center'>
    <div class='row justify-content-center'>
      <div class='col-md-6 col-10'>
        <h4 class='w-100 text-center mt-1'>{{ loadingMessage }}</h4>
        <progressbar class='align-items-center ih-progress-bar' [animate]="false" [value]="loadingProgress" [striped]='true' [max]='100' type="success">
        </progressbar>
      </div>
    </div>
  </div>
</div>

<div id="mainTop" *ngIf='!loading' class="container-fluid viewer" (scroll)="onScroll()">
  <next-viewer-main
    [formId]='submission?.id'
    [experience]="experience"
    [attachments]="attachments"
    [initialState]="formData"
    [needsValidation]="needsValidation"
    (valueChanged)="valueChanged($event)">
  </next-viewer-main>
  <next-viewer-footer
    [experience]="experience"
    [selectedPage]="selectedPage"
    [allowSave]="true"
    [submitDisabled]="btnDisabled"
    (nextPageEvent)="goNextPage()"
    (prevPageEvent)="goPrevPage()"
    (saveEvent)="submit(SubmissionType.Saved)"
    (cancelEvent)="cancel()"
    (submitEvent)="submit(SubmissionType.Submitted)"
    (needsValidationEvent)="validateRequiredFields()">
  </next-viewer-footer>
  <next-viewer-warning-ribbon></next-viewer-warning-ribbon>
</div>
