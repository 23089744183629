<ng-container *ngIf="componentList.includes(field.inputType); else original">
  <next-ssn
    *ngIf="field.inputType === TextboxInputType.SOCIAL"
    [form]="form"
    [field]="field"
    [initialState]="initialState"
    [needsValidation]="needsValidation"
    (statusChange)="statusChange.emit($event)"
    (valueChanged)="emitValueChanged($event)">
  </next-ssn>
  <next-creditcard
    *ngIf="field.inputType === TextboxInputType.CREDITCARDDIGITS"
    [form]="form"
    [field]="field"
    [initialState]="initialState"
    [needsValidation]="needsValidation"
    (statusChange)="statusChange.emit($event)"
    (valueChanged)="emitValueChanged($event)">
  </next-creditcard>
  <next-phone
    *ngIf="field.inputType === TextboxInputType.PHONE"
    [form]="form"
    [field]="field"
    [initialState]="initialState"
    [needsValidation]="needsValidation"
    (statusChange)="statusChange.emit($event)"
    (valueChanged)="emitValueChanged($event)">
  </next-phone>
</ng-container>

<ng-template #original>
  <div class="form-group rounded" [ngClass]="{'d-none' : field.hidden}" [formGroup]="valueFormGroup">
    <label for="{{field.name}}" class="m-0 field_text">
      <span class="">{{field.text}}</span>
      <span *ngIf="field.required" class="required bold">&nbsp;*</span>
    </label>

    <!-- Alphanumeric Multiline Alpha Numeric Email -->
    <div *ngIf="field.inputType === TextboxInputType.ALPHANUM || field.inputType === TextboxInputType.NUM || field.inputType === TextboxInputType.ALPHA
  || field.inputType === TextboxInputType.EMAIL || field.inputType === TextboxInputType.REGULAREXPRESSION" class="input-group">

      <div *ngIf="field.inputType === TextboxInputType.EMAIL" class="input-group-prepend">
        <span class="input-group-text"><fa-icon [icon]="['fas', 'envelope']" class="margin-center"></fa-icon></span>
      </div>

      <input
        *ngIf="!field.multiLine"
        class="form-control"
        formControlName="Text"
        autocomplete="off"
        id="{{ field.name }}"
        name="{{ field.name }}"
        [placeholder]="field.placeholder"
        [type]="type[field.inputType]"
        [attr.inputmode]="inputMode[field.inputType] ? inputMode[field.inputType] : null"
        [readonly]="field.readonly"
        [required]="field.required"
        [maxlength]="field?.maxChar ? (field.maxChar > 0 ? field.maxChar : null) : null"
        [pattern]="pattern[field.inputType] ? pattern[field.inputType] : null"
        [ngClass]="{'invalid' : valueFormGroup.get('Text')?.invalid && !newField && needsValidation}"
        [mask]="masking[field.inputType] ? masking[field.inputType] : null"
        [patterns]="maskPatterns[field.inputType] ? maskPatterns[field.inputType] : null"
        [allowNegativeNumbers]="true"
        [dropSpecialCharacters]="false">

      <!-- Paragraph is Alphanumeric (No Input Type Restriction) only -->
      <textarea
        *ngIf="field.multiLine"
        class="form-control hide-placeholder-focus"
        formControlName="Text"
        autocomplete="off"
        id="{{ field.name }}"
        name="{{ field.name }}"
        [placeholder]="field.placeholder"
        [readonly]="field.readonly"
        [required]="field.required"
        [maxlength]="field?.maxChar ? (field.maxChar <= 0 ? null : field.maxChar) : null"
        [ngClass]="{'invalid' : valueFormGroup.get('Text')?.invalid && !newField && needsValidation}">
    </textarea>
    </div>

    <!-- Date -->
    <div *ngIf="field.inputType === TextboxInputType.DATE">
      <div class="input-group calendar">
        <input
          #ngxDatePicker="bsDatepicker"
          class="form-control"
          bsDatepicker
          id="{{ field.name + '_datePicker' }}"
          name="{{ field.name + '_datePicker' }}"
          [bsValue]="datePickerValue"
          [bsConfig]="datePickerConfig"
          (bsValueChange)="datePickerSetDate($event)">
      </div>
      <div class="input-group" [formGroup]="dateTextInputFg">
        <div class="input-group-prepend cursor-pointer" role="button" (click)="ngxDatePicker.show()" [style.pointerEvents]="field.readonly ? 'none' : 'auto'">
          <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="margin-center"></fa-icon></span>
        </div>
        <input
          class=form-control
          type="text"
          formControlName="value"
          id="{{ field.name + '_text' }}"
          name="{{ field.name + '_text' }}"
          autocomplete="off"
          [readonly]="field.readonly"
          [placeholder]="field.placeholder || (field.dateFormat | uppercase)"
          [mask]="dateMask || null"
          [leadZeroDateTime]="true"
          [specialCharacters]="['/']"
          (change)="textInputSetDate($event)"
          [ngClass]="{'invalid' : valueFormGroup.get('Text')?.invalid && !newField && needsValidation}">
      </div>
    </div>

    <!-- Currency -->
    <div *ngIf="field.inputType === TextboxInputType.CURRENCY" class="input-group">
      <div class="input-group-prepend">
      <span class="input-group-text">
        <fa-icon [icon]="['fas', 'dollar-sign']" class="margin-center"></fa-icon>
      </span>
      </div>
      <input
        class="form-control"
        formControlName="Text"
        autocomplete="off"
        id="{{ field.name }}"
        name="{{ field.name }}"
        type="text"
        inputmode="decimal"
        [placeholder]="field.placeholder"
        [readonly]="field.readonly"
        [required]="field.required"
        [maxlength]="field?.maxChar ? (field.maxChar > 0 ? field.maxChar : null) : null"
        [ngClass]="{'invalid' : valueFormGroup.get('Text')?.invalid && !newField && needsValidation}"
        [mask]="'separator.2'"
        [thousandSeparator]="','"
        [dropSpecialCharacters]="true"
        [allowNegativeNumbers]="false">
    </div>

    <!-- Postal Code -->
    <div *ngIf="field.inputType === TextboxInputType.POSTALCODE" class="input-group">
      <div class="input-group-prepend">
      <span class="input-group-text">
        <fa-icon [icon]="['fas', 'map-pin']" class="margin-center"></fa-icon>
      </span>
      </div>
      <input
        class="form-control"
        formControlName="Text"
        autocomplete="off"
        id="{{ field.name }}"
        name="{{ field.name }}"
        [placeholder]="field.placeholder"
        [type]="'text'"
        [attr.inputmode]="'numeric'"
        [readonly]="field.readonly"
        [required]="field.required"
        [maxlength]="10"
        [mask]="'00000-0000'"
        [value]="valueFormGroup.value['Text'] | mask: '00000-0000 || 00000'"
        [pattern]="'(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)'"
        [ngClass]="{'invalid' : valueFormGroup.get('Text')?.invalid && !newField && needsValidation}"
        [dropSpecialCharacters]="false">
    </div>

    <!-- Short Date MM/YY -->
    <div *ngIf="field.inputType === TextboxInputType.SHORTDATE2">
      <input
        class="form-control"
        formControlName="Text"
        autocomplete="off"
        id="{{ 'GX-Viewer-Field-TextBox' + field.name }}"
        name="{{ 'GX-Viewer-Field-TextBox' + field.name }}"
        [attr.inputmode]="inputMode[field.inputType] ? inputMode[field.inputType] : null"
        [placeholder]="'MM/YY'"
        [type]="type[field.inputType]"
        [readonly]="field.readonly"
        [required]="field.required"
        [maxlength]="5"
        [pattern]="pattern[field.inputType] ? pattern[field.inputType] : null"
        [ngClass]="{'invalid' : valueFormGroup.get('Text')?.invalid && !newField && needsValidation}"
        [mask]="ShortDate2Mask"
        [leadZeroDateTime]="true"
        [dropSpecialCharacters]="false">
    </div>

    <!-- Short Date MM/YYYY -->
    <div *ngIf="field.inputType === TextboxInputType.SHORTDATE4">
      <input
        class="form-control"
        formControlName="Text"
        autocomplete="off"
        id="{{ 'GX-Viewer-Field-TextBox' + field.name }}"
        name="{{ 'GX-Viewer-Field-TextBox' + field.name }}"
        [attr.inputmode]="inputMode[field.inputType] ? inputMode[field.inputType] : null"
        [placeholder]="'MM/YYYY'"
        [type]="type[field.inputType]"
        [readonly]="field.readonly"
        [required]="field.required"
        [maxlength]="7"
        [pattern]="pattern[field.inputType] ? pattern[field.inputType] : null"
        [ngClass]="{'invalid' : valueFormGroup.get('Text')?.invalid && !newField && needsValidation}"
        [mask]="ShortDate4Mask"
        [leadZeroDateTime]="true"
        [dropSpecialCharacters]="false">
    </div>

    <!-----------------------
     Validation Error Message
     ----------------------->
    <ng-container *ngIf="valueFormGroup?.get('Text')">
      <span *ngIf="valueFormGroup?.get('Text').invalid && !newField && needsValidation" class="float-right text-sizing color-danger">
        {{ getFormattingError(valueFormGroup?.get('Text')) | translate:getFormattingErrorParam(valueFormGroup?.get('Text')) }}
      </span>
    </ng-container>
  </div>
</ng-template>
